<template>
  <div class="home">
    <div class="pic1"></div>
    <div class="pic2"></div>
    <div class="pic3">
      <el-carousel class="srolle1" indicator-position="none">
        <el-carousel-item v-for="item in imgList1" :key="item">
          <img :src="require(`@/assets/${item}`)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="pic4">
      <el-carousel class="srolle2" indicator-position="none">
        <el-carousel-item v-for="item in imgList2" :key="item">
          <img :src="require(`@/assets/${item}`)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="pic5"></div>
    <div class="pic6"></div>
    <div class="pic7"></div>
    <div class="pic8">
      <el-carousel class="srolle3" indicator-position="none">
        <el-carousel-item v-for="item in imgList3" :key="item">
          <img :src="require(`@/assets/${item}`)" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="footer">
      <div class="footer-text">
        <div class="text1">
          <div class="item1">
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
              Copyright 2023 圆伦大厦 浙ICP备2023049800号-1
            </a>
          </div>
          <div>地址：杭州市滨江区齐飞路350号（地铁5/6号线长河站G口）</div>
        </div>
        <div class="text2">圆伦大厦公众号</div>
        <img :src="require('@/assets/qrCode.jpg')" class="qrCode" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      imgList1: ['group1-1.png', 'group1-2.png', 'group1-3.png'],
      imgList2: ['group2-1.png', 'group2-2.png', 'group2-3.png', 'group2-4.png'],
      imgList3: ['group3-1.png', 'group3-2.png', 'group3-3.png', 'group3-4.png'],
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  padding: 0;
  margin: 0;

  .pic1 {
    width: 100%;
    height: 11.17rem;
    background-image: url('../assets/1.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .pic2 {
    width: 100%;
    height: 40.29rem;
    background-image: url('../assets/2.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .pic3 {
    width: 100%;
    height: 10.36rem;
    background-image: url('../assets/3.png');
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 3.18rem 3.6rem 0 3.6rem;
    box-sizing: border-box;

    .srolle1 {
      width: 100%;
      height: 6.42rem;
      box-sizing: border-box;

      ::v-deep .el-carousel__container {
        height: 6.42rem;

        img {
          width: 100%;
          height: 6.42rem;
        }

        .el-carousel__arrow {
          width: 0.36rem;
          height: 0.36rem;
          font-size: 0.12rem;
        }
      }
    }
  }
  .pic4 {
    width: 12rem;
    height: 10.18rem;
    background-image: url('../assets/4.png');
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 3.6rem;
    padding-top: 4.31rem;
    box-sizing: border-box;

    .srolle2 {
      width: 100%;
      height: 5.89rem;
      box-sizing: border-box;
      // border: 0.01rem solid #000;

      ::v-deep .el-carousel__container {
        height: 5.89rem;

        img {
          width: 100%;
          height: 5.89rem;
        }

        .el-carousel__arrow {
          width: 0.36rem;
          height: 0.36rem;
          font-size: 0.12rem;
        }
      }
    }
  }
  .pic5 {
    width: 100%;
    height: 30.51rem;
    background-image: url('../assets/5.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .pic6 {
    width: 12rem;
    height: 12.67rem;
    background-image: url('../assets/6.png');
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 3.6rem;
  }
  .pic7 {
    width: 100%;
    height: 46.99rem;
    background-image: url('../assets/7.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .pic8 {
    width: 12rem;
    height: 11.46rem;
    background-image: url('../assets/8.png');
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 0 3.6rem;
    box-sizing: border-box;

    .srolle3 {
      width: 100%;
      height: 8rem;
      box-sizing: border-box;

      ::v-deep .el-carousel__container {
        height: 8rem;

        img {
          width: 100%;
          height: 8rem;
        }

        .el-carousel__arrow {
          width: 0.36rem;
          height: 0.36rem;
          font-size: 0.12rem;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 1.43rem;
    margin-top: 1.78rem;
    background: #efefef;

    .footer-text {
      width: 12rem;
      height: 100%;
      margin: 0 3.6rem;
      display: flex;
      align-items: center;

      .text1 {
        width: 5.35rem;
        color: #333333;
        font-family: PingFang SC, PingFang SC-Regular;
        text-align: left;
        font-size: 0.14rem;
        margin-right: 2.94rem;

        .item1 {
          margin-bottom: 0.1rem;
          a {
            color: #333333;
            text-decoration: none;
          }
        }
      }
      .text2 {
        color: #866020;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC-Regular;
        text-align: left;
        margin-right: 0.65rem;
      }
      .qrCode {
        width: 1.09rem;
        height: 1.09rem;
      }
    }
  }
}
</style>
